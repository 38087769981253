// useGoogleAds.js
import { useEffect } from "react";

const useGoogleAds = () => {
  useEffect(() => {
    // Insertar el código de la etiqueta global de Google Ads
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-16730367491";
    script.async = true;
    document.head.appendChild(script);

    const inlineScript = document.createElement("script");
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-16730367491');
    `;
    document.head.appendChild(inlineScript);
  }, []);

  const trackConversion = (url) => {
    const callback = () => {
      if (typeof url !== "undefined") {
        window.location = url; // Redirigir al link de WhatsApp
      }
    };

    if (typeof window.gtag !== "undefined") {
      // Disparar el evento de conversión con callback
      window.gtag("event", "conversion", {
        send_to: "AW-16730367491/J9ezCITErNsZEIPM1Kk-",
        event_callback: callback,
      });
    } else {
      // Si gtag no está listo, redirige inmediatamente
      callback();
    }
  };

  return { trackConversion };
};

export default useGoogleAds;
