import React from "react";
import { Container, Button } from "react-bootstrap";
import {
  FaWhatsapp,
  FaFacebook,
  FaInstagram,
  FaPhoneSquareAlt,
} from "react-icons/fa";
import GalleryComponent from "./components/GalleryComponent";
import useGoogleAds from "./GoogleAds";
import "./App.css";

function App() {
  // Usa el hook para cargar el script de Google Ads.
  const { trackConversion } = useGoogleAds();

  const handleWhatsappClick = () => {
    trackConversion(
      "https://wa.me/3513092465?text=Hola,%20estoy%20interesado%20en%20los%20carteles%20comerciales"
    );
  };

  return (
    <div className="App">
      {/* Descripción breve */}
      <section className="description text-center">
        <Container className="p-0">
          <h1 className="main-title">
            Carpinteria Quinteros Córdoba Argentina
          </h1>
          <p className="main-description">
            <strong>Carteles de madera comerciales para tu negocio.</strong>
            <br />
            Cuotas semanales fijas y accesibles.
            <br />
            <em>Te lo llevamos sin cargo hasta tu local.</em>
          </p>
        </Container>
      </section>

      <div className="text-center my-5">
        {/* Mensaje de contacto */}
        <p className="main-description">Consultanos sin compromiso:</p>
        <p className="contact-info">
          <FaPhoneSquareAlt className="phone-icon" />
          <strong>3513092465</strong>
        </p>

        <br />

        {/* Botón de WhatsApp */}
        <Button
          variant="success"
          size="lg"
          onClick={handleWhatsappClick} // Llamar a la función cuando se hace clic en el botón
          className="whatsapp-button"
        >
          <FaWhatsapp className="mr-2" size={46} /> Whatsapp
        </Button>
      </div>

      {/* Sección de Galería */}
      <section className="gallery-section my-5">
        <GalleryComponent />
      </section>

      {/* Sección de Redes Sociales */}
      <footer className="social-media text-center py-4">
        <a
          href="https://www.facebook.com/profile.php?id=61564948526974"
          target="_blank"
          rel="noopener noreferrer"
          className="mx-3"
        >
          <FaFacebook size={36} />
        </a>
        <a
          href="https://www.instagram.com/picidae.carpinteria/"
          target="_blank"
          rel="noopener noreferrer"
          className="mx-3"
        >
          <FaInstagram size={36} />
        </a>
      </footer>
    </div>
  );
}

export default App;
