import React, { useState, useEffect } from "react";
import Gallery from "react-photo-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

// Importar automáticamente todas las imágenes de assets cuyo nombre comience con "image"
const importAllImages = (r) => r.keys().map(r);

// Carga solo imágenes que empiezan con "image" desde la carpeta assets
let images = importAllImages(
  require.context("../assets", false, /^\.\/image.*\.(png|jpe?g|svg)$/)
);

// Ordenar las imágenes por el número en su nombre
images = images.sort((a, b) => {
  const numberA = parseInt(a.match(/image(\d+)/)[1], 10);
  const numberB = parseInt(b.match(/image(\d+)/)[1], 10);
  return numberA - numberB;
});

function GalleryComponent() {
  const [photos, setPhotos] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  useEffect(() => {
    const loadImages = async () => {
      const promises = images.map(
        (src) =>
          new Promise((resolve) => {
            const img = new Image();
            img.src = src;
            img.onload = () => {
              resolve({
                src,
                width: img.width,
                height: img.height,
              });
            };
          })
      );

      const loadedPhotos = await Promise.all(promises);
      setPhotos(loadedPhotos);
    };

    loadImages();
  }, []);

  const openLightbox = (event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div className="gallery-container">
      {photos.length > 0 && <Gallery photos={photos} onClick={openLightbox} />}
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
        />
      )}
    </div>
  );
}

export default GalleryComponent;
